.title {
  font-weight: 'bold';
  font-size: 18;
  line-height: '20px';
  margin-bottom: 5px;
  letter-spacing: 0.2px;
}

.form-label {
  font-size: 16px;
}

//APPLICANT CARD
.card__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .close__button {
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
    padding: 5px;
  }

  .name__container {
    display: flex;
    flex-direction: column;
  }
}

// APPLICANT INFO MODAL

.line__container {
  .line__container__title {
    margin-bottom: 0;
  }

  .info__container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .info__container__item {
  }

  .info__container__item__label {
    margin: 0;
    font-size: 12px;
    color: rgb(157, 156, 156);
  }

  .info__container__item__text {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
  }

  .info__container__empty__row {
    height: 8px;
  }
}
