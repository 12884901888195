@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  font-family: 'Mulish', sans-serif !important;
}

.auth-app-logo {
  width: 200px;
}
.ant-layout {
  background: white;
}

// ANT OVERRIDES
.ant-layout-sider-trigger {
  background-color: #bac3c8;
}

.ant-menu {
  .ant-menu-item-selected {
    background-color: #6e828c !important;
  }
}
.ant-table-thead > tr > th {
  background: white !important;
}
// .ant-table-tbody > tr > td {
//   padding: 11px;
// }
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  min-height: 73px !important;
}

.ant-table-cell {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 0.2px !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.textArea {
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #465968;
}

.inProgress {
  background-color: rgba(255, 238, 84, 0.25);
}

.scheduled {
  background-color: rgba(207, 234, 254, 0.25);
}

.ant-slider {
  height: 20px;
  padding: 5px 0;
  .ant-slider-track,
  .ant-slider-step,
  .ant-slider-rail {
    height: 10px;
    border-radius: 5px;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
  }
}

.filter-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.5);
}

.new-form-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.75);
}

.button-new-form {
  background: #d7dee2;
  border-radius: 2px;
}

.button-cancel-form {
  background: #ffffff;
  border: 1px solid #d7dee2;
  box-sizing: border-box;
  border-radius: 2px;
}

.btn-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
}

.skill-text {
  // line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  padding: 0;
  margin: 0;
}

.subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
}

.card-custom-padding > .ant-card-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.row-text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  font-style: normal;
  font-weight: normal;
}
.skills > .ant-space-item {
  margin-bottom: 0 !important;
}

.schedule-label {
  font-weight: 200;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #585858;
}

@mixin fsclasses($from, $to) {
  @for $i from $from through $to {
    .fs-#{$i} {
      font-size: #{$i}px;
    }
  }
}
@include fsclasses(10, 50);

@mixin fwclasses($from, $to) {
  @for $i from $from through $to {
    .fw-#{$i * 100} {
      font-weight: #{$i * 100};
    }
  }
}
@include fwclasses(1, 10);

.custom-radio > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent !important;
}

.incident-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
}

@mixin pClasses($from, $to) {
  @for $i from $from through $to {
    .pl-#{$i} {
      padding-left: #{$i * 10}px;
    }
  }
}
@include pClasses(1, 10);

@mixin mClasses($from, $to) {
  @for $i from $from through $to {
    .ml-#{$i} {
      margin-left: #{$i * 10}px;
    }
  }
}
@include mClasses(1, 10);

.bg-light {
  background-color: #f8f8f8;
}

.dark-color {
  color: rgba(0, 0, 0, 0.5);
}
.light-color {
  color: rgba(0, 0, 0, 0.75);
}

.custom {
  .ant-picker-input input {
    width: 6ch;
  }
  .date-slider {
    .date-slider-item {
      background-color: rgb(229, 229, 229);
      box-sizing: border-box;
      border-width: 1px;
      border-color: rgb(70, 89, 104);
      border-style: solid;
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  .ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 13px;
    font-weight: 600;
    background: #e5e5e5;
    border: 1px solid #465968 !important;
    border-top-width: 1.02px !important;
    cursor: pointer;
    -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s,
      -webkit-box-shadow 0.3s;
    transition: color 0.3s, background 0.3s, border-color 0.3s,
      -webkit-box-shadow 0.3s;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s,
      -webkit-box-shadow 0.3s;
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      &:active,
      &:hover {
        color: #096dd9;
      }
      z-index: 1;
      background: #b8b8b8;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

.ant-table-row {
  height: 73px;
}
.sub-row {
  padding: 40px;
}

.no-border-row {
  td {
    border: 0 !important;
  }
}
